import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import BodyRenderer from '../components/BodyRenderer'
import Popup from '../components/Popup'


function PageTemplate (props) {
  const {
    data: { contentfulPage }
  } = props
  const { locale, node_locale } = contentfulPage

  return (
    <>
      <Helmet
        description={contentfulPage.description}
        htmlAttributes={{ lang: node_locale || locale }}
        title={contentfulPage.metaTitle}
      >
        <meta
          content={contentfulPage.metaDescription}
          name="description"
        />
        <meta content={contentfulPage.metaTitle} name="title" />
      </Helmet>
      {/* {contentfulPage.slug === 'home'
       && <Popup locale={node_locale} />} */}
      <BodyRenderer json={contentfulPage.body.json} />
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(id: { eq: $id }) {
      body {
        json
      }
      metaTitle
      metaDescription
      title
      slug
      node_locale
    }
  }
`
