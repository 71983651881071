import { faArrowDown, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Container from '@material-ui/core/Container'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useReducer } from 'react'
import styled, { keyframes } from 'styled-components'

const getBackgroundImage = graphql`
 {
  backgroundImageDE: contentfulAsset(file: {fileName: {eq: "Wie-wir-Sie-vor-dem-Coronavirus-schu__tzen.png"}}) {
    fluid {
      ...GatsbyContentfulFluid
    }
  }
  backgroundImageEN: contentfulAsset(file: {fileName: {eq: "Our-measures-to-protect-you-against-Coronavirus.png"}}) {
    fluid {
      ...GatsbyContentfulFluid
    }
  }
}
`

const visibility = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`

/* Popup container */
const Popup = styled.div`
    position: absolute;
    display: ${props => (props.visible ? 'inline-block' : 'none')};
    cursor: pointer;
    //animation: ${visibility} 300ms ease-in;
    width: 100%;
    top: calc(86px + 20px);
    z-index: 10000;
    @media (max-width: 960px) {
      top: initial;
    }
  `

const StyledContainer = styled(Container)`
  background-color: #ebebeb;
  width: 50%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  @media (max-width: 960px) {
    width: 95%;
  }
  @media (min-width: 960px) {
    padding-bottom: 32px;
  }
  @media (min-width: 600px) {
    padding-bottom: 24px;
  }
`

const PopupText = styled.p` 
    display: ${props => (props.info ? 'initial' : 'none')};
    background-color: #fff;
    padding: 10px;
    b {
      font-weight: 900;
    }
  `


const BackgroundImage = styled(Img)``

const X = styled(FontAwesomeIcon)`
  position: relative;
  align-self: flex-end;
  margin: 20px;
  width: 48px !important;
  height: 48px;
`

const Button = styled.div`
    background-color: #1b1e27;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 15px;
    font-family: Barlow;
    display: flex;
    align-items: center;

    svg {
      margin: 10px;
    }
`

function PopUp(props) {
  const { locale } = props
  const [visible, toggleVisible] = useReducer(() => false, true)
  const [info, toggleInfo] = useReducer(state => !state, false)
  const background = useStaticQuery(getBackgroundImage)

  return (
    <Popup visible={visible}>
      <StyledContainer>
        <X icon={faTimes} onClick={toggleVisible} />
        <BackgroundImage fluid={locale === 'en' ? background.backgroundImageEN.fluid : background.backgroundImageDE.fluid}><p>overlay</p></BackgroundImage>
        <Button onClick={toggleInfo}>
          <FontAwesomeIcon icon={info ? faArrowDown : faArrowRight} />
          <p>{locale === 'en' ? 'More information' : 'Mehr Informationen'}</p>
        </Button>

        <PopupText info={info}>
          {locale === 'en' ? (
            <>
              <b>Dear customer,</b>
              <p>The coronavirus currently dominates the news and many people are worried. Of course, we also take the latest developments / information seriously and would like to inform you about our measures to protect you as well as our employees. The safety and health of our customers and employees is our top priority.
              </p>
              <b>The following measures have already been taken:</b>
              <ul>
                <li>All employees on duty have been tested for the coronavirus</li>
                <li>All vehicles in use have been cleaned and disinfected thoroughly</li>
                <li>All vehicles are equipped with disinfectant spray in order to disinfect the vehicle after each trip</li>
                <li>Disinfection dispensers and disinfection wipes are available in all vehicles for free use</li>
                <li>We will refrain from handshake greetings until further notice</li>
              </ul>
              <p>We are in contact with the medical service and the health authorities in order to be able to react appropriately to the dynamic situation at any time. We promptly arranged the test for the employees. We absolutely want to avoid that there are employees on duty who feel healthy but may already be infected with the virus.
              </p>
              <p>Information about the good cough and sneeze etiquette, as well as strict hand hygiene rules have been refreshed, however this has always been provided by all employees at A2B | mobility in motion at all time and is taken very seriously.
              </p>
              <p>If you have any questions about your booking or our measures, we will be happy to help you.
              </p>
              <p>Yours sincerely</p>
              <p>Sabine Richartz</p>
              <p>CEO & Founder</p>
            </>
          )
            : (
              <>
                <b>Sehr geehrte Kundinnen und Kunden</b>
                <p>Aktuell beherrschen die Meldungen um das Coronavirus die Nachrichten und viele Menschen machen sich
                Sorgen. Auch wir nehmen die Informationen ernst und möchten Sie darüber informieren wie wir damit
                umgehen, um Sie und unsere Mitarbeiter zu schützen. Ihre Sicherheit und Gesundheit, sowie die unserer
                Mitarbeiter genießen bei uns die höchste Priorität.
                </p>
                <b>Daher wurden bereits folgende Maßnahmen umgesetzt:</b>
                <ul>
                  <li>Alle im Dienst befindlichen Mitarbeiter wurden auf das Coronavirus getestet</li>
                  <li>Alle eingesetzten Fahrzeuge wurden intensiv gereinigt und desinfiziert</li>
                  <li>Alle Fahrzeuge sind mit Desinfektionsspray ausgestattet, um das Fahrzeug nach jeder Fahrt neu zu desinfizieren</li>
                  <li>Desinfektionsspender und Desinfektionstücher stehen Ihnen in allen Fahrzeugen zur kostenlosen Nutzung zur Verfügung</li>
                  <li>Auf Begrüßungen per Handschlag werden wir bis auf weiteres verzichten</li>
                </ul>
                <p>Wir stehen mit dem Medizinische Dienst und den Gesundheitsämtern im Austausch, um auf die dynamische Lage jederzeit angemessen reagieren zu können. Den Test für die Mitarbeiter haben wir sehr schnell veranlasst. Wir möchten damit ausschließen, dass sich Mitarbeiter im Einsatz befinden, die sich gesund fühlen aber trotzdem bereits mit dem Virus infiziert sein könnten.
                </p>
                <p>Informationen über die Husten- und Niesetikette, sowie eine strenge Händehygiene wurden aufgefrischt, wurden aber immer schon von allen Mitarbeitern bei A2B | mobilty in motion sehr ernst genommen.
                </p>
                <p>Sollten Sie Fragen zu Ihrer Buchung oder unseren Maßnahmen haben, stehen wir Ihnen gerne beratend zur Verfügung.
                </p>
                <p>Mit freundlichen Grüßen</p>
                <p>Sabine Richartz</p>
                <p>CEO & Founder</p>
              </>
            )}
        </PopupText>
      </StyledContainer>
    </Popup>
  )
}

export default PopUp
